import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./history.css";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function HistoryCasino() {
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    function formatDate(m) {
        var m = new Date(m);
        m.setHours(m.getHours() - 1);
        const dateString =
            m.getUTCFullYear() +
            "/" +
            ("0" + (m.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + m.getDate()).slice(-2) +
            "  " +
            ("0" + m.getHours()).slice(-2) +
            ":" +
            ("0" + m.getMinutes()).slice(-2);
        return dateString;
    }
    const [history, setHistory] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [endDate, setEndDate] = useState(new Date());
    useEffect(() => {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const formattedDate = `${year}${month}${day}`;
        axios
            .post(`${process.env.REACT_APP_API_URL}/casino/historymember`, { date: formattedDate })
            .then((res) => {
                setHistory(res.data.data);
            })
            .catch((err) => function () {});
    }, []);
    const selectHistory = (date) => {
        if (isLoading == true) return;
        setLoading(true);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const formattedDate = `${year}${month}${day}`;
        axios
            .post(`${process.env.REACT_APP_API_URL}/casino/historymember`, { date: formattedDate })
            .then((res) => {
                setHistory(res.data.data);
                setLoading(false);
            })
            .then((res) => {
                setLoading(false);
            });
    };
    const productId = (data) => {
        let a;
        switch (data.toString()) {
            case "4":
                a = "AG";
                break;
            case "79":
                a = "BBIN";
                break;
            case "104":
                a = "CMD";
                break;
            case "16":
                a = "CQ9";
                break;
            case "27":
                a = "DG";
                break;
            case "86":
                a = "Evolution";
                break;
            case "142":
                a = "FB";
                break;
            case "140":
                a = "JILI";
                break;
            case "121":
                a = "KingMaker";
                break;
            case "102":
                a = "V8 Poker";
                break;
            case "123":
                a = "King Poker";
                break;
            case "43":
                a = "MG";
                break;
            case "39":
                a = "PP";
                break;
            case "174":
                a = "SABA";
                break;
            case "118":
                a = "WM";
                break;
            case "112":
                a = "SEXY";
                break;
            case "54":
                a = "SBO";
                break;
            case "151":
                a = "UG";
                break;
            case "99":
                a = "TF";
                break;
            case "111":
                a = "Playtech";
                break;
            case "28":
                a = "AllBet";
                break;
            case "55":
                a = "JDB";
                break;
            case "202":
                a = "WS168";
                break;
            case "61":
                a = "IMES";
                break;
            case "132":
                a = "SV388";
                break;
            default:
                a = "";
                break;
        }
        return a;
    };
    return (
        <>
            {isLoading ? (
                <div className="loading" style={{ maxWidth: "100%" }}>
                    <div className="loader"></div>
                </div>
            ) : null}
            <div className="historyrecharge">
                <div className="top_title">
                    <div className="txt">Lịch sử sảnh game</div>
                </div>
                <center style={{ margin: "10px 0" }}>
                    <DatePicker
                        maxDate={new Date()}
                        selected={endDate}
                        onChange={(date) => {
                            setEndDate(date);
                            selectHistory(date);
                        }}
                    />
                </center>
                {history ? (
                    <div className="main-history">
                        <div className="header-history">
                            <div style={{ width: "10%" }}>Sảnh</div>
                            <div style={{ width: "10%" }}>Loại</div>
                            <div style={{ width: "20%" }}>Tên trò chơi</div>
                            <div style={{ width: "10%" }}>Số tiền cược</div>
                            <div style={{ width: "10%" }}>Số tiền thắng</div>
                            <div style={{ width: "20%" }}>Mã giao dịch</div>
                            <div style={{ width: "20%" }}>Thời gian</div>
                        </div>
                        {history?.map((item, key) => (
                            <>
                                <div className="content-history">
                                    <div style={{ width: "10%" }}>{productId(item?.productType)}</div>
                                    <div style={{ width: "10%" }}>{item?.gameCategory}</div>
                                    <div style={{ width: "20%" }}>{item?.gameName}</div>
                                    <div style={{ width: "10%" }}>{item?.betAmount?.toLocaleString("vi-VN")}</div>
                                    <div style={{ width: "10%" }}>{item?.winAmount?.toLocaleString("vi-VN")}</div>
                                    <div style={{ width: "20%" }}>{item?.betOrderNo}</div>
                                    <div style={{ width: "20%" }}>{formatDate(new Date(item?.betTime))}</div>
                                </div>
                            </>
                        ))}
                    </div>
                ) : (
                    <div></div>
                )}
            </div>
        </>
    );
}
export default HistoryCasino;
